import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Checkbox, Typography } from 'antd';
import styled from 'styled-components';

import { AssetsForms } from '@/entities/assets';
import { ContactsForms } from '@/entities/contact';
import { DocumentsForms } from '@/entities/document';
import { NextOwnerCreateForm } from '@/entities/next-owner/helpers';
import { Container, Icon, Row } from '@/shared/components';
import { Drawer } from '@/shared/components/drawer';
import { COLORS } from '@/shared/constants/colors';
import { useCreateBeneficiaryMutation } from '@/shared/generated/graphql';
import {
  OmitedBaseDto,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { useFormRender } from '@/shared/lib/hooks/use-form-render';
import { useFormSave } from '@/shared/lib/hooks/use-form-save';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { DocumentType } from '@/shared/lib/sj-orm/models/document/document-base.dto';
import { PrivateDocumentType } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';
const { Text } = Typography;

const FormsByCollection = {
  [CollectionName.ASSETS]: AssetsForms,
  [CollectionName.CONTACTS]: ContactsForms,
  [CollectionName.PRIVATE_DOCUMENTS]: DocumentsForms,
  [CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS]: DocumentsForms,
  [CollectionName.BENEFICIARIES]: NextOwnerCreateForm,
};

type TFormType = TDtoWrapper<
  OmitedBaseDto,
  { [TK in keyof OmitedBaseDto]: QuestionType }
>;

export const FormDrawerProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const [form, setForm] = useState<TFormType | undefined>();
  const [collectionName, setCollectionName] = useState<
    CollectionName | undefined
  >();
  const [type, setType] = useState<string | undefined>();
  const [isSendInvite, setIsSendInvite] = useState(false);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (router.query.fastAdd) {
      const [collection, item] = (router.query.fastAdd as string).split(
        ':',
      ) as [
        (
          | CollectionName.ASSETS
          | CollectionName.CONTACTS
          | CollectionName.PRIVATE_DOCUMENTS
          | CollectionName.BENEFICIARIES
        ),
        string,
      ];

      const forms = FormsByCollection[collection] as
        | Record<string, TFormType | TFormType[] | undefined>
        | undefined;

      if (!forms) return;

      const form_ =
        collection === CollectionName.BENEFICIARIES
          ? forms
          : forms[
              CollectionName.PRIVATE_DOCUMENTS === collection
                ? PrivateDocumentType.UNILATERAL
                : item
            ];

      //   const result = []

      const resultForm: TFormType = {} as TFormType;

      const formForMap = Array.isArray(form_) ? form_[0] : form_;

      if (!formForMap) {
        setForm(undefined);
        return;
      }

      for (const [key, val] of Object.entries(formForMap)) {
        if (val.required) resultForm[key as keyof TFormType] = val;
      }

      setCollectionName(collection);
      setForm(resultForm);
      setType(item);
    }
  }, [router.query]);

  const close = useCallback(async () => {
    setForm(undefined);
    const query = { ...router.query };
    delete query.fastAdd;
    delete query.fastTrack;
    await router.replace({ query });
    setIsSendInvite(false);
  }, [router]);

  const afterSubmit = useCallback(async () => {
    setForm(undefined);
    const query = { ...router.query };
    delete query.fastAdd;
    delete query.fastTrack;

    await router.replace({
      query: {
        ...query,
        isFastAdded: true,
        isInvited: isSendInvite,
      },
    });

    setIsSendInvite(false);
  }, [router, isSendInvite]);

  return (
    <>
      {!!form && !!collectionName && !!type && (
        <Drawer
          height={'70vh'}
          placement="bottom"
          closable={false}
          onClose={close}
          open={!!form}
        >
          <Row justifyContent="space-between" alignCenter nowrap>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Add item
            </Typography.Title>

            <Icon
              icon="close"
              cursorPointer
              onClick={close}
              color={COLORS.colorLink}
            />
          </Row>
          <Container marginTop={24} marginBottom={16}>
            <Form
              form={form}
              afterSubmit={afterSubmit}
              collectionName={collectionName}
              type={type}
              isSendInvite={isSendInvite}
            />
          </Container>

          {collectionName === CollectionName.BENEFICIARIES && (
            <StyledLabel>
              <Checkbox
                value={isSendInvite}
                onChange={(e) => setIsSendInvite(e.target.checked)}
              />
              <StyledText>Send invitation to the Family Member</StyledText>
            </StyledLabel>
          )}
        </Drawer>
      )}

      {children}
    </>
  );
};

const additionalByCollection: Partial<
  Record<CollectionName, Record<string, unknown>>
> = {
  [CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS]: {
    type: DocumentType.PERSONAL_IDENTIFIERS,
    beneficiaryId: 'me',
  },
  [CollectionName.PRIVATE_DOCUMENTS]: {
    type: DocumentType.PRIVATE_DOCUMENTS,
    privateType: PrivateDocumentType.UNILATERAL,
  },
  [CollectionName.BENEFICIARIES]: {},
};

const keyByCollectionName: Partial<Record<CollectionName, string>> = {
  [CollectionName.ASSETS]: 'category',
  [CollectionName.CONTACTS]: 'type',
  [CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS]: 'personalIdentifierType',
  [CollectionName.PRIVATE_DOCUMENTS]: 'privateCategory',
};

const Form = ({
  collectionName,
  form,
  isSendInvite = false,
  type,
  afterSubmit,
}: {
  afterSubmit: () => void;
  collectionName: CollectionName;
  form: TFormType;
  isSendInvite: boolean;
  type: string;
}) => {
  const { finish } = useFormSave({
    updateOrSave: 'save',
    collectionName,
    initialDtoData: {
      [keyByCollectionName[collectionName] ?? 'type']: type,
      ...(additionalByCollection[collectionName] || {}),
    },
  });

  const [createBeneficiaryMutation] = useCreateBeneficiaryMutation();

  const onSave = async (data: OmitedBaseDto) => {
    let additionalData = {};

    if (collectionName === CollectionName.BENEFICIARIES) {
      const centralDbBeneficiary = await createBeneficiaryMutation({
        variables: {
          email: (data as unknown as { email: string }).email,
          dontSendInviteEmail: !isSendInvite,
        },
      });

      additionalData = {
        centralDbProfileId:
          centralDbBeneficiary.data?.createBeneficiary.account.id,
        invitationCode:
          centralDbBeneficiary.data?.createBeneficiary.invitationCode,
        linkedCountryCode: '',
      };
    }

    finish({ ...data, ...additionalData });

    if ('email' in data) {
      localStorage.setItem(`new-family-member-added`, String(data.email));
    }

    afterSubmit();
  };

  const { component } = useFormRender({ form, onSubmit: onSave });

  return component;
};

const StyledText = styled(Text)`
  &&& {
    font-family: 'Avenir Next Cyr';
  }
`;

const StyledLabel = styled.label`
  display: flex;
  gap: 8px;
`;
