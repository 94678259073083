import { Migrator } from '@/shared/lib/migrator/migrator';
import {
  EncryptionType,
  KeyPair,
} from '@/shared/lib/secure-json/core/crypto-core/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { Loader } from '@/shared/lib/synchronizer/loaders/types';

import { SerializedCollection } from './types';

export class Synchronizer {
  private readonly migrator: Migrator;

  constructor(
    private readonly encryptionType: EncryptionType,
    private readonly keyPair: KeyPair,
    private readonly loader: Loader,
    private readonly onCollectionLoaded: (
      name: CollectionName,
      collection: SecureJsonBase,
    ) => void,
  ) {
    this.migrator = new Migrator(keyPair.publicKey);
  }

  public async push(
    collectionName: CollectionName,
    collection: SerializedCollection,
  ): Promise<boolean> {
    await this.loader.push(collectionName, collection);
    return true;
  }

  public async pull(collectionName: CollectionName): Promise<boolean> {
    const serializedCollection: SerializedCollection | null =
      await this.loader.pull(collectionName);
    const collections = new Map<CollectionName, SecureJsonBase>();

    const defaultCollection = new SecureJsonBase(
      this.keyPair.privateKey,
      this.encryptionType,
    );
    const collection = serializedCollection
      ? await defaultCollection.fromStringAsync(serializedCollection)
      : defaultCollection;

    collections.set(collectionName, collection);

    // TODO clean
    if (['assets'].includes(collectionName)) {
      console.log('[col] pull', collectionName, collection);
    }

    // todo make async migration async and process only one collection at a time
    const migratedCollections = this.migrator.up(collections);
    this.onCollectionLoaded(
      collectionName,
      migratedCollections.get(collectionName)!,
    );

    return true;
  }
}

// todo добавить колбэки на пуш пул чтобы можно было ловить события и отображать/скрывать лоадеры фризящие интерфейс
